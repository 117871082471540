// @font-face {
//   font-family: 'cooper_hewittmedium_italic';
//   src: url('../fonts/cooperhewitt-mediumitalic-webfont.eot');
//   src: url('../fonts/cooperhewitt-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/cooperhewitt-mediumitalic-webfont.woff2') format('woff2'),
//     url('../fonts/cooperhewitt-mediumitalic-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'cooper_hewittmedium';
  src: url('../fonts/cooperhewitt-medium.eot');
  src: url('../fonts/cooperhewitt-medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/cooperhewitt-medium.woff2') format('woff2'),
    url('../fonts/cooperhewitt-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sofia_prolight';
  src: url('../fonts/sofiapro-light-webfont.eot');
  src: url('../fonts/sofiapro-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/sofiapro-light-webfont.woff2') format('woff2'),
    url('../fonts/sofiapro-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Faune Text';
  src: url('../fonts/faune-text-bold.eot');
  src: url('../fonts/faune-text-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/faune-text-bold.woff2') format('woff2'),
    url('../fonts/faune-text-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  box-sizing: inherit;
}

body {
  scroll-behavior: smooth;
  font-family: 'sofia_prolight', Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  color: #303030;
}

main {
  padding-bottom: 4em;
}

.wrapper {
  position: relative;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper-small {
  max-width: 720px;
}

h2 {
  position: relative;
  text-align: center;
  font-size: 1.316em;
  font-family: 'cooper_hewittmedium', Arial, Helvetica, sans-serif;
  padding-bottom: .5em;
  letter-spacing: 1px;
  font-weight: 600;

  i {
    display: block;
    width: 100%;

    &::before {
      position: relative;
      display: block;
      width: 1.875em;
      height: 1.875em;
      line-height: 1.875em;
      margin: 0 auto 8px;
      background-color: #1abc9c;
      color: #fff;
      border-radius: 1.875em;
      font-size: 1.5em;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    display: block;
    left: 50%;
    bottom: -1px;
    width: 22px;
    height: 2px;
    transform: translateX(-50%);
    background-color: #303030;
  }
}

h3 {
  font-weight: normal;
  text-align: center;
  font-weight: 600;
  margin-top: 1.5em;
}

h4 {
  font-size: 0.895em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin: 0;
}

hr {
  position: relative;
  display: block;
  width: 22px;
  height: 2px;
  margin: 10px auto 20px;
  background-color: #303030;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;

  &:hover,
  &:focus {
    color: #1abc9c;
  }
}

//
// Header
//

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: height .5s ease;
  z-index: 10;

  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &.scrolled,
  &.open {
    background-color: #fff;

    .logo {
      width: 146px * .555555;
      height: 90px * .555555;

      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 0;
      }
    }

    nav a {
      color: #000;
    }

    .btn-menu::before {
      color: #000;
    }
  }

  &.open {
    nav {
      max-height: 100vh;
      overflow: auto;
    }
  }
}

.logo {
  position: relative;
  display: block;
  width: 146px * .555555;
  height: 90px * .555555;
  text-indent: -9em;
  transform-origin: 0 0;
  margin: 5px 0;
  overflow: hidden;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .5s ease;
  }

  &::before {
    background: url(/static/logos/clair.png) no-repeat center/contain;
    opacity: 1;
  }

  &::after {
    background: url(/static/logos/sombre.png) no-repeat center/contain;
    opacity: 0;
  }
}

.btn-menu {
  border: none;
  background: transparent;
  outline: 0;

  &::before {
    content: "\02630";
    font-size: 1.5em;
  }
}

nav {
  width: 100%;
  max-height: 0;
  transition: height .5s ease;
  overflow: hidden;
  transition: all .5s ease;

  a {
    display: block;
    color: #000;
    padding: 1em 0;
    font-size: 0.938em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    transition: color .5s ease;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }
  }
}

@media all and (min-width: 960px) {
  header {
    height: 100px;

    &.scrolled {
      height: 60px;

      .logo {
        width: 146px * .555555;
        height: 90px * .555555;
      }
    }
  }

  .logo {
    width: 146px;
    height: 90px;
  }

  .btn-menu {
    display: none;
  }

  nav {
    display: flex;
    width: auto;
    align-items: center;
    max-height: none;

    a {
      padding: 0 1em;
      border-bottom: none;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}


//
// Home
//

.masthead {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  &+main section:first-child {
    padding-top: 4em;
  }

  .title-top,
  .title-bottom {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 1em 2em;
    font-family: 'Faune Text', sans-serif;
    font-size: 2.75vw;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    color: #00969F;
    z-index: 1;

    &::before {
      content: " ";
      position: absolute;
      left: 50%; width: 200%; height: 100vh;
      background-color: #fff;
      transform: translateX(-50%) rotate(-14deg);
      opacity: 0.8;
      z-index: -1;
    }

    &::after {
      content: " ";
      position: absolute;
      width: 40vh; height: 5px;
      background-color: #fff;
      transform: rotate(73deg);
      transform-origin: left center;
    }
  }

  .title-top {
    padding-top: 120px;

    span:last-child {
      margin-left: 3em;
    }

    &::before {
      bottom: 0;
      transform-origin: 40% bottom;
    }

    &::after {
      bottom: 0;
      left: 2em;
    }
  }

  .title-bottom {
    bottom: 0;
    text-align: right;

    span:last-child {
      margin-right: 3.5em;
    }

    &::before {
      top: 0;
      transform-origin: 62% top;
    }

    &::after {
      top: 0;
      right: 2em;
      transform: rotate(73deg);
      transform-origin: right center;
    }
  }
}

section {
  font-size: 1.188em;
  padding-top: 4em;

  &.grey {
    background-color: #f2f2f2;
    padding-bottom: 4em;
    margin-top: 4em;

    &:first-child {
      margin-top: 0;
    }
  }

  &:first-child {
    padding-top: 8em;
  }
}

.intro-logo {
  max-width: 300px;
}

.align-center {
  text-align: center;
}

.article-blocs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.article-bloc {
  width: 33.333%;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;

  .article-image {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    // width: 100%;
    // height: 0;
    // padding-top: 66.667%;

    img {
      display: block;
      // position: absolute;
      // top: 0;
      // left: 50%;
      // width: auto;
      // height: 100%;
      // transform: translateX(-50%);
      // max-width: none;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
    font-size: 1em;
    line-height: 1.1;
  }

  p {
    font-size: 0.75em;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .article-bloc {
    width: 50%;
  }

  .masthead {
    .title-top, .title-bottom {
      padding: 1em;
      font-size: 2em;
      text-align: left;

      span{
        margin: 0 !important;
      }

      &::before{
        transform-origin: 25% center;
      }

      &::after{
        height: 3px;
        width: 30vh;
      }
    }

    .title-top{
      padding-top: 80px;
      &::before{
        transform-origin: 50% center;
      }
    }

  }
}

@media screen and (max-width: 425px) {
  .article-bloc {
    width: 100%;
  }

  .masthead {
    .title-top, .title-bottom {
      padding: 20px;
      font-size: 1.5em;
    }
    .title-top{
      padding-top: 80px;
    }
  }
}


//
// Article
//

article {
  &>* {
    max-width: 664px;
    margin-left: auto;
    margin-right: auto;
  }

  .article-image {
    max-width: none;
  }

  .article-title {
    margin-bottom: 10px;
  }

  .article-date {
    font-size: 0.75em;
    margin-bottom: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }

  h2::after {
    left: 0;
    transform: none;
  }

  a {
    color: #1abc9c;
    text-decoration: underline;
  }

}

@media screen and (max-width: 1100px) {
  article .article-image {
    margin-left: -20px;
    margin-right: -20px;
  }
}


//
// Nos actions
//

.module {
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
    margin-top: 0;
  }

  img {
    display: block;
  }

  figure {
    margin: 0;
    max-width: 30%;
    margin-right: 3%;
  }

  figcaption {
    padding: 10px;
    background-color: #000;
    color: #fff;
    font-size: 0.75em;
  }
}

@media screen and (max-width: 768px) {
  .module {
    display: block;

    figure {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}




//
// Qui sommes-nous
//

.members {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    width: 100%;
  }
}

.member {
  max-width: 220px;
  width: 31.333%;
  margin: 10px 1%;
  background: #fff;

  h4 {
    padding: 16px 16px 8px;
  }

  p {
    position: relative;
    margin: 0 0 24px;
    padding: 0 16px 16px;
    font-size: 0.737em;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      left: 50%;
      bottom: -1px;
      width: 22px;
      height: 2px;
      transform: translateX(-50%);
      background-color: #1abc9c;
    }
  }
}

.avatar {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  background-color: #c5c4c3;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    max-width: none;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 768px) {
  .member {
    width: 48%;
    max-width: none;
  }
}

@media screen and (max-width: 425px) {
  .member {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}


//
// Contact
//

label {
  font-size: 14px;
  line-height: 26px;
  color: #818181;
  font-weight: 400;
}

.btn,
input[type=submit] {
  position: relative;
  color: #ffffff;
  border-color: #ffffff;
  background-color: #31c8a2;
  height: 41px;
  line-height: 38px;
  margin: 0;
  padding: 0 23px;
  font-size: 13px;
  font-weight: 700;
  font-family: inherit;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  transition: all .1s linear;
  border: solid #ffffff 2px;

  &:hover {
    border-color: #31c8a2;
  }
}

input[type=text],
input[type=email],
textarea {
  width: 100%;
  display: block;
  margin: 5px 0 0;
  padding: 15px;
  border: 0;
  border-radius: 0;
  outline: 0;
  resize: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  color: #818181;
  font-weight: 400;
  box-sizing: border-box;
  background-color: #f6f6f6;
}